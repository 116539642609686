.header_desktop {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 4vh 0;

	.switch {
		margin: 0;
	}
}

.header_mobile {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 2vh 2vh;

	h1 {
		font-size: 16px;
	}

	.switch {
		padding-top: 2vh;
		display: flex;
		flex-direction: column;
		margin: 0;
	}
}

.rainbow-divider {
	width: 100%;
}
