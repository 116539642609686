.Portfolio {
	background-color: #b5c9e4;

	h3 {
		font-weight: 100;
	}
}

.wrapper_desktop {
	height: 75vh;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 5%;
}

.textBox_desktop {
	width: 50%;
	h1 {
		text-align: left;
	}
}

.textBox_mobile {
	padding: 5%;
}

.icon-links_desktop {
	img {
		max-width: 50px;
	}
	display: flex;
	justify-content: space-between;
	padding: 0 20% 0 20%;
}

.icon-links_mobile {
	img {
		max-width: 4vh;
	}
	display: flex;
	justify-content: space-between;
	padding: 0 20% 5% 20%;
}

.fun-work_desktop {
	width: 40%;
	text-align: right;
}

.fun-work_mobile {
	background-color: #ffffff;
	padding: 5%;
}