#Welcome {
	height: 100vh;

	h1 {
		text-align: right;
		padding-right: 10%;
		line-height: 1;
	}
}

.switch {
	z-index: 100;
	position: relative;
	margin: 4vh;
	display: flex;
	align-items: center;

	p {
		padding-left: 2vh;
	}
}

#Welcome:after {
	content: "";
	background-image: url("../../assets/inversion.png");
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.5;
}

.welcome_desktop {
	.logo {
		width: 20%;
		opacity: 1;
		z-index: 1;
	}
}

.welcome_mobile {
	.logo {
		width: 100%;
		opacity: 1;
		z-index: 1;
	}

	.switch {
		display: flex;
		flex-direction: column;
		align-items: start;
	}
	.definition {
		padding-top: 5vh;
		padding-left: 5vh;

	}
	
}

.animate__animated.animate__fadeInUp {
	--animate-duration: 3s;
}

.animate__animated.animate__fadeIn {
	--animate-duration: 7s;

}
.definition {
	padding-top: 20vh;
	padding-left: 10vh;
	color: rgb(57, 56, 56);
}
