.nineties-contact {
	padding-left: 5vh;

	input {
		border-radius: 0;
	}

	textarea {
		border-radius: 0;
	}

	.submit_button {
		color: yellow;
	}
}

@media only screen and (max-width: 800px) {
	#NinetiesContact {
		padding: 0 5vh 5vh 5vh;

		input {
			width: 100%;
		}
	}
}
