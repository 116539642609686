.Contact {
	height: 60vh;
	background-color: #81858f;
	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
		text-align: center;
		padding-top: 2%;
	}

	p {
		padding: 0 5vh;
	}
}

#form {
	display: flex;
	flex-direction: row;
}

.inputs {
	display: flex;
	flex-direction: column;
	margin-right: 5vh;
}

input {
	border-radius: 5px;
	margin-bottom: 1vh;
	width: 40vh;
	height: 4vh;
	border: black solid 1px;
}
textarea {
	margin-bottom: 1vh;
	height: 20vh;
	border-radius: 5px;
	border: black solid 1px;
	width: 40vh;
}

.submit_button {
	width: 41vh;
}

@media only screen and (max-width: 800px) {
	.Contact {
		height: 90vh;
	}
	textarea {
		width: 98%;
	}

	.inputs {
		margin-right: 0;
	}

	#form {
		flex-direction: column;
	}

	.submit_button {
		width: 100%;
	}
}
