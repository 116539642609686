@font-face {
	font-family: "Comic Sans MS";
	src: url("src/fonts/ComicSansMS3.ttf");
}

#Nineties {
	height: 100%;
	background-size: cover;
	background-image: url("https://i.imgur.com/SHZe50s.gif");
	color: yellow;
	font-family: "Comic Sans MS", sans-serif;
	background-repeat: repeat;
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	a:hover {
		background-color: white;
	}
}

h1 {
	text-align: center;
	line-height: 0;
}

span.line {
	color: red;
}

.content_desktop {
	padding: 0 5vh;
}

.content_mobile {
	padding: 0;
	p {
		font-size: 12px;
	}

	h2 {
		padding: 0 3vw;
	}
}

.blink {
	-webkit-animation: blink 0.75s linear infinite;
	-moz-animation: blink 0.75s linear infinite;
	-ms-animation: blink 0.75s linear infinite;
	-o-animation: blink 0.75s linear infinite;
	animation: blink 0.75s linear infinite;
}
@-webkit-keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	50.01% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@-moz-keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	50.01% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@-ms-keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	50.01% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@-o-keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	50.01% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	50.01% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
