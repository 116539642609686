#links {
	padding: 0 5vh;
}

.image-links {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.single-link {
	display: flex;
	flex-direction: row;
	text-align: center;

	img {
		max-height: 5vh;
	}
}

@media only screen and (max-width: 800px) {
	.single-link {
		img {
			max-height: 3vh;
		}
	}
}
